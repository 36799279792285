import { useState, useCallback } from "react";

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);

  const toggle = useCallback(
    (ref) => {
      setIsShowing((isShowing) => !isShowing);
    },
    [isShowing]
  );
  const [isShowing2, setIsShowing2] = useState(false);

  const toggle2 = useCallback(
    (ref) => {
      setIsShowing2((isShowing2) => !isShowing2);
    },
    [isShowing2]
  );

  return {
    isShowing,
    toggle,
    isShowing2,
    toggle2,
  };
};

export default useModal;
