import React, { memo, useCallback } from "react";
import { Resizable } from "re-resizable";
import HeightIcon from "@material-ui/icons/Height";
import formatOpacity from "utils/formatOpacity";

export default memo(({ onChange, ballState, title, enable }) => {
  const style = {
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    position: "relative",
    padding: 12,
    textAlign: "center",
    justifyContent: "center",
    opacity: ballState.opacity ? formatOpacity(ballState.opacity) : 1,
    background: ballState.color ? ballState.color : "#ff3917",
  };

  const handleSaveSize = useCallback(
    (e, d, ref, delta) => {
      const theHeight = ballState.sizes.height + delta.height;
      const theWidth = ballState.sizes.width + delta.width;

      if (theHeight < 80 || theWidth < 80) {
        onChange({
          sizes: {
            width: 80,
            height: 80,
          },
        });
      } else if (theHeight > 300 || theWidth > 300) {
        onChange({
          sizes: {
            width: 300,
            height: 300,
          },
        });
      } else {
        onChange({
          sizes: {
            width: theWidth,
            height: theHeight,
          },
        });
      }
    },
    [ballState]
  );

  const handleType = useCallback(
    (hand) => {
      const fHand = ballState.handles.map((hd) => {
        if (hd.position === hand.position) {
          return {
            ...hd,
            type:
              hand.type === "inactive"
                ? "source"
                : hand.type === "source"
                ? "target"
                : "inactive",
          };
        } else {
          return hd;
        }
      });
      onChange({ handles: fHand });
    },
    [ballState]
  );

  return (
    <>
      <Resizable
        size={ballState.sizes}
        minWidth={80}
        maxWidth={300}
        style={style}
        enable={enable}
        onResizeStop={handleSaveSize}
        lockAspectRatio={true}
      >
        {ballState.handles.map((hand) => (
          <div
            key={hand.position}
            className={`${
              enable && hand.type === "inactive" ? "hidden" : ""
            } handle`}
            onClick={() => handleType(hand)}
            style={{
              background:
                hand.type === "source"
                  ? "#77DD76"
                  : hand.type === "target"
                  ? "#FF6962"
                  : "#707070",
              [hand.position]: -15,
            }}
          />
        ))}

        {!enable && (
          <div className="heightIconS blobSmall blue">
            <HeightIcon style={{ color: "#195ead" }} />
          </div>
        )}

        <span style={{ fontSize: 14, color: "#fff" }}>{title}</span>
      </Resizable>
    </>
  );
});
