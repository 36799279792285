import React, { useState, useCallback, useEffect, useContext } from "react";
import Modal from "components/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { putServerData, delServerData } from "actions";
import { useRouter } from "next/router";
import Button from "@material-ui/core/Button";
import { useSession } from "next-auth/client";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Ball from "../AddBall/Ball";
import { ChartContext } from "providers/chart";
import { toast } from "react-toastify";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { siteUrl } from "utils/urls";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    fontSize: 14,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  ballBox: {
    width: "100%",
    maxWidth: 350,
    height: 350,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loaderCont: {
    width: "100%",
    display: "flex",
    marginBottom: 12,
  },
  alertCont: {
    width: "100%",
    display: "flex",
    paddingBottom: 22,
    justifyContent: "center",
  },
}));

function ModalEdit({ isShowing, toggle, data, leitura }) {
  const classes = useStyles();
  const { setEditing, allLines } = useContext(ChartContext);

  const [inputVal, setInputVal] = useState(130);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [session] = useSession();
  const router = useRouter();

  const [ballState, setBallState] = useState({
    sizes: {
      width: 130,
      height: 130,
    },
    color: "#f33333",
    handles: [
      {
        type: "inactive",
        position: "left",
      },
      {
        type: "inactive",
        position: "right",
      },
      {
        type: "inactive",
        position: "top",
      },
      {
        type: "inactive",
        position: "bottom",
      },
    ],
  });

  const handleBallState = useCallback(
    (ballInfo) => {
      console.log(ballInfo);
      setBallState((bState) => ({
        ...bState,
        ...ballInfo,
      }));
      setInputVal(
        ballInfo.sizes ? ballInfo.sizes.width : ballState.sizes.width
      );
    },
    [ballState]
  );

  const handleInputVal = useCallback((e) => {
    setInputVal(e.target.value);
  }, []);

  const handleBallSize = useCallback((e) => {
    if (e.target.value < 80) {
      e.target.value = 80;
    } else if (e.target.value > 300) {
      e.target.value = 300;
    }
    setInputVal(e.target.value);
    setBallState((bState) => ({
      ...bState,
      sizes: {
        width: e.target.value,
        height: e.target.value,
      },
    }));
  }, []);

  const handleColor = useCallback((e) => {
    setBallState((bState) => ({
      ...bState,
      color: e.target.value,
    }));
  }, []);

  const handleOpacity = useCallback((e) => {
    if (e.target.value > 10) {
      setBallState((bState) => ({
        ...bState,
        opacity: 10,
      }));
    } else if (e.target.value < 0) {
      setBallState((bState) => ({
        ...bState,
        opacity: 0,
      }));
    } else {
      setBallState((bState) => ({
        ...bState,
        opacity: e.target.value,
      }));
    }
  }, []);

  const handleEdit = useCallback(async () => {
    setLoading(true);
    try {
      await putServerData(
        `fontes/${data.id}`,
        {
          size: ballState.sizes.width,
          color: ballState.color,
          opacity: ballState.opacity,
          handles: ballState.handles.filter((hd) => hd.type !== "inactive"),
        },
        session.accessToken
      );

      setLoading(false);
      // router.push("/admin");
      window.location = siteUrl + "admin";
      toggle();
    } catch (error) {
      setTimeout(() => {
        setLoading(false);
      }, 200);
      toast.error("Erro de conexão, tente mais tarde");
      console.log(error);
    }
  }, [session, router, toggle, ballState]);

  useEffect(() => {
    let newHandles = ballState.handles.map((hd) => {
      let findIn = data.handles.find((h) => h.position === hd.position);
      if (findIn) {
        return { ...findIn };
      } else {
        return hd;
      }
    });

    setInputVal(data.size);

    setBallState((bState) => ({
      ...bState,
      color: data.color,
      sizes: { width: data.size, height: data.size },
      handles: newHandles,
    }));
  }, [data]);

  useEffect(() => {
    if (!isShowing) {
      setEditing(true);
    }
  }, [isShowing]);

  const handleDelete = useCallback(async () => {
    setLoading(true);
    setAlert(false);
    try {
      const linesToDelete = allLines.filter((lin) =>
        ballState.handles.some(
          (hd) =>
            String(hd.id) === lin.sourceHandle ||
            String(hd.id) === lin.targetHandle
        )
      );

      for (const el of linesToDelete) {
        await delServerData(`ligacoes/${el.id}`, session.accessToken);
      }

      await delServerData(`fontes/${data.id}`, session.accessToken);
      setLoading(false);
      window.location = siteUrl + "admin";
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Erro de conexão, tente mais tarde");
    }

    //verificar qual linha tem a fonte aqui ou target
  }, [allLines, ballState, session]);

  const handleClose = () => {
    setAlert(false);
  };
  const openAlert = () => {
    setAlert(true);
  };

  return (
    <Modal leitura={leitura} isShowing={isShowing} hide={toggle}>
      <Paper className="editBox flex-column">
        <div className="container-full justify-content-between">
          <div className={classes.ballBox}>
            <Ball
              ballState={ballState}
              onChange={handleBallState}
              title={data.departamento && data.departamento.titulo}
            />
          </div>

          <div className="container-full inputColorBox d-flex flex-column">
            <TextField
              label="Tamanho"
              value={inputVal}
              onChange={handleInputVal}
              onBlur={handleBallSize}
              type="number"
              variant="outlined"
            />

            <TextField
              label="Cor"
              value={ballState.color}
              onChange={handleColor}
              type="color"
              variant="outlined"
            />

            <TextField
              label="Opacidade"
              value={ballState.opacity}
              onChange={handleOpacity}
              type="number"
              variant="outlined"
            />

            <span style={{ fontSize: 14, marginBottom: 12 }}>
              Clique nos pontos para ativar as entradas e saídas:
            </span>
            <div className="d-flex">
              <small className="cBox d-flex">
                Desativado: <div className="gray"></div>
              </small>
              <small className="cBox d-flex">
                Entrada: <div className="blue"></div>
              </small>
              <small className="cBox d-flex">
                Saída: <div className="orange"></div>
              </small>
            </div>
          </div>
        </div>

        <div className={classes.loaderCont}>
          {loading && <LinearProgress style={{ width: "100%" }} />}
        </div>

        <div className={classes.loaderCont}>
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={handleEdit}
            className={classes.button}
          >
            Salvar
          </Button>

          <Button
            disabled={loading}
            variant="contained"
            color="secondary"
            onClick={openAlert}
            className={classes.button}
          >
            Excluir
          </Button>
        </div>
      </Paper>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={alert}
      >
        <DialogTitle id="simple-dialog-title">
          Deseja excluir esta fonte?
        </DialogTitle>
        <div className={classes.alertCont}>
          <Button
            disabled={loading}
            variant="contained"
            color="secondary"
            onClick={handleClose}
            className={classes.button}
          >
            Fechar
          </Button>

          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={handleDelete}
            className={classes.button}
          >
            Confirmar
          </Button>
        </div>
      </Dialog>
    </Modal>
  );
}

export default ModalEdit;
