import React, { memo, useState, useRef, useEffect } from "react";

import ReactDOM from "react-dom";

const Modal = ({ isShowing, hide, children, classe, leitura }) => {
  const [show, setShow] = useState(isShowing);
  const [notShow, setNotShow] = useState(!isShowing);

  const wrapperRef = useRef(null);

  const timeRef = useRef(null);

  useEffect(() => {
    if (isShowing) {
      setShow(true);

      setNotShow(false);
      clearTimeout(timeRef.current);
    } else {
      setShow(false);
      timeRef.current = setTimeout(() => setNotShow(true), 800);
    }
  }, [isShowing]);

  /* unmount cleanup */
  useEffect(() => () => clearTimeout(timeRef.current), []);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          hide();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  return !notShow
    ? ReactDOM.createPortal(
        <React.Fragment>
          <section
            data-aos="zoom-in"
            className={`${
              !show && "hidden"
            } modal d-flex align-items-center justify-content-center`}
          >
            <div
              ref={wrapperRef}
              className={`modalBody d-flex ${classe} align-items-center justify-content-center flex-column`}
            >
              <div onClick={hide} className="closeModal">
                <img
                  src={leitura ? "images/close.svg" : "/images/close.svg"}
                  alt="close"
                />
              </div>
              {children}
            </div>
          </section>
        </React.Fragment>,
        document.body
      )
    : null;
};

export default memo(Modal);
