import React, { useState, createContext } from "react";

export const ChartContext = createContext({});

export const ChartProvider = (props) => {
  const [editing, setEditing] = useState(true);

  const [allLines, setAllLines] = useState([]);

  // const [activePage, setActivePage] = useState({
  //   admin:true,
  //   hierarquia:false,

  // });

  return (
    <ChartContext.Provider
      value={{ editing, setEditing, allLines, setAllLines }}
    >
      {props.children}
    </ChartContext.Provider>
  );
};
