import React, { useState, useCallback, useContext } from "react";
import { Resizable } from "re-resizable";
import { useSession } from "next-auth/client";
import { toast } from "react-toastify";
import { Handle } from "react-flow-renderer";
import formatOpacity from "utils/formatOpacity";
import { putServerData } from "actions";
import EditIcon from "@material-ui/icons/Edit";
import ModalEdit from "./ModalEdit";
import useModal from "hooks/useModal";
import { ChartContext } from "providers/chart";

const Ball = ({ data, selected }) => {
  const { setEditing } = useContext(ChartContext);
  const [session] = useSession();
  const { isShowing, toggle, isShowing2, toggle2 } = useModal();

  const { dat, handles, theSizes, enable, leitura, handleClick } = data;

  const [sizes, setSizes] = useState(theSizes);

  const style = {
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    position: "relative",
    justifyContent: "center",
    padding: 12,
    textAlign: "center",
    opacity: dat.opacity ? formatOpacity(dat.opacity) : 1,
    border: selected ? "1px solid #000" : "solid 1px #ddd",
    background: dat.color ? dat.color : "#ff3917",
  };

  const showModal = useCallback(() => {
    handleClick(dat);
  }, [enable, handleClick, dat]);

  const handleSaveSize = useCallback(
    async (e, d, ref, delta) => {
      if (enable) {
        return;
      }

      setSizes({
        width: sizes.width + delta.width,
        height: sizes.height + delta.height,
      });

      try {
        await putServerData(
          `fontes/${dat.id}`,
          { size: sizes.width + delta.width },
          session.accessToken
        );
      } catch (error) {
        toast.error("Erro de conexão, tente mais tarde");
        console.log(error);
      }
    },
    [sizes, session]
  );

  const openEdit = useCallback(() => {
    toggle2();
    setEditing(false);
  }, [toggle2]);

  return (
    <>
      <Resizable
        onClick={showModal}
        size={sizes}
        minWidth={80}
        maxWidth={300}
        style={style}
        onResizeStop={handleSaveSize}
        //DESATIVEI O RESIZE AKI
        enable={{
          top: false,
          right: false,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        lockAspectRatio={true}
      >
        {handles &&
          handles.length > 0 &&
          handles.map((hand) => {
            const { type, position } = hand;
            const posName = position;

            return (
              <Handle
                key={String(hand.id)}
                type={type}
                id={String(hand.id)}
                position={position}
                style={{
                  background: type === "source" ? "#77DD76" : "#FF6962",
                  [posName]: -8,
                }}
              />
            );
          })}

        {/* {selected && (
          <div className="heightIcon blobSmall white">
            <HeightIcon fontSize="small" style={{ color: "#fff" }} />
          </div>
        )} */}

        {selected && !enable && (
          <div onClick={openEdit} className="editIcon blobSmall white">
            <EditIcon fontSize="small" style={{ color: "#fff" }} />
          </div>
        )}

        <span style={{ fontSize: 14, color: "#fff" }}>
          {dat.departamento && dat.departamento.titulo}
        </span>
      </Resizable>

      {!enable && (
        <ModalEdit
          sizes={sizes}
          leitura={leitura}
          isShowing={isShowing2}
          toggle={toggle2}
          data={dat}
        />
      )}
    </>
  );
};

export default Ball;
